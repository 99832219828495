import {PageActions, SkeletonDisplayText} from '@shopify/polaris-internal';
import React from 'react';

import styles from './SkeletonPageActions.module.css';

export function SkeletonPageActions() {
  return (
    <PageActions
      primaryAction={
        <div className={styles.ButtonWrapper}>
          <SkeletonDisplayText size="large" />
        </div>
      }
    />
  );
}
